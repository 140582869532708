import * as React from "react"
import { useEffect, useState } from "react"

import Layout from "../Layout/layout"
import Seo from "../seo"
import Review from "./review"
import FindBusinessOptions from "./findBusinessOptions"
import FindPeopleOptions from "./findPeopleOptions"

import "./contactsFinder.css"
import AxiosInstance from "../../services/axiosInstance"

const FindContacts = () => {
  const [findBy, setFindBy] = useState("Businesses")
  const [searchType, setSearchType] = useState("AIChatGPT")
  const [step, setStep] = useState("search")
  const [findPeopleBy, setFindPeopleBy] = useState("RadiusSearch")
  const [importType, setImportType] = useState("Create a New")
  const [groups, setGroups] = useState([])

  const handleSearchFeature = event => {
    setFindBy(event.target.value)
  }

  const handleSearchType = event => {
    setSearchType(event.target.value)
  }

  const handleSearch = () => {
    setStep("review")
  }

  const handleImportType = event => {
    setImportType(event.target.value)
  }

  const getContactGroups = () => {
    return new Promise(async resolve => {
      await AxiosInstance.get(`/contact/group`, {
        params: {
          page: 1,
        },
      }).then(response => {
        setGroups(response.data.items)
      })
    })
  }
  useEffect(() => {
    getContactGroups().then(r => r)
  }, [])

  return (
    <Layout>
      <Seo title="Find Contacts" />
      <div id="right-section" className="h-100 contacts-finder">
        <div className="row g-0">
          <div className="col">
            <div className="row">
              <div className="col-lg-12">
                <div className="row g-0">
                  <div className="col-sm-12">
                    <h1>
                      Find Contacts{" "}
                      <a
                        href="https://swiftcrm.com/support/csv-import"
                        target="_blank"
                        rel="noreferrer"
                        className="fs-4"
                      >
                        <i className="bi bi-question-circle-fill" />
                      </a>
                    </h1>
                  </div>
                  <div className="col-sm-12 mb-3">
                    <ul className="nav nav-wizard">
                      <li
                        className={`step1 ${
                          step === "search" ? "SaleStatus_Pending" : "SaleStatus_NotStarted"
                        }`}
                      >
                        <a href="#">
                          <span> 1. Set Criteria</span>
                        </a>
                      </li>
                      <li
                        className={`step2 ${
                          step === "search" ? "SaleStatus_NotStarted" : "SaleStatus_Pending"
                        }`}
                      >
                        <a href="#">
                          <span>2. Review & Import</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {step === "search" && (
                  <div className="row step1_container">
                    <div className="col-lg-12 text-left importTypeContainer">
                      <div className="row row-cols-lg-auto g-2 align-items-center mb-3">
                        <div className="col-12">
                          <strong>Find</strong>
                        </div>
                        <div className="col-12">
                          <select
                            className="form-select search_type"
                            onChange={handleSearchFeature}
                            defaultValue="Businesses"
                          >
                            <option value="Businesses">Businesses</option>
                            <option value="People">People</option>
                            <option value="InstagramFollowersOf">Instagram Followers of...</option>
                            <option value="InstagramFollowingOf">Instagram Following of...</option>
                          </select>
                        </div>
                        <div className="col-12">
                          <strong>using</strong>
                        </div>
                        {findBy === "Businesses" && (
                          <div className="col-12 business_opt">
                            <select className="form-select search_by" onChange={handleSearchType}>
                              <option value="AIChatGPT">AI / ChatGPT</option>
                              <option value="GoogleBusiness">Google My Business</option>
                              <option value="FacebookBusinesses">Facebook Businesses</option>
                              <option value="LinkedIn">LinkedIn</option>
                            </select>
                          </div>
                        )}
                        {findBy === "People" && (
                          <div className="col-12 people_opt">
                            <select
                              className="form-select find_people_by"
                              onChange={e => {
                                setFindPeopleBy(e.target.value)
                              }}
                            >
                              <option value="RadiusSearch">Radius Search</option>
                              <option value="CopyFromFB">Copy &amp; Paste from Facebook™</option>
                              <option value="CopyFromLinkedIn">Copy &amp; Paste from LinkedIn™</option>
                            </select>
                          </div>
                        )}
                        {["InstagramFollowersOf", "InstagramFollowingOf"].includes(findBy) && (
                          <div className="col-12 findByTypeOpt findByInstagram">
                            <div className="input-group">
                              <span className="input-group-text">@</span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="username1, username2"
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      {findBy === "People" && <FindPeopleOptions findPeopleBy={findPeopleBy} />}
                      {findBy === "Businesses" && <FindBusinessOptions searchType={searchType} />}

                      <div className="mb-3">
                        <div className="row row-cols-lg-auto g-2 align-items-center">
                          <div className="col-12">
                            <select className="form-select" name="importType" onChange={handleImportType}>
                              <option value="Create a New">Create New Group / Audience</option>
                              <option value="Add to Existing">Add to Existing Group / Audience</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {importType === "Create a New" && (
                        <div className="mb-3 row createNewAudienceRow">
                          <label className="col-lg-3 col-sm-4 col-form-label">
                            Name this Audience / Group as...
                          </label>
                          <div className="col-lg-9 col-sm-8">
                            <input type="text" name="group_name" id="group_name" className="form-control" />
                          </div>
                        </div>
                      )}
                      {importType === "Add to Existing" && (
                        <div className="mb-3 row addToExistingAudience">
                          <label className="col-lg-3 col-sm-4 col-form-label">Group / Audience</label>
                          <div className="col-lg-9 col-sm-8">
                            <div className="row row-cols-lg-auto g-2 align-items-center">
                              <div className="col-12 importAsDropdown">
                                <select className="form-select">
                                  <option>== AUDIENCE / GROUP ==</option>
                                  {groups.map((group, index) => {
                                    return (
                                      <option value={group.id} key={index}>
                                        {group.name}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                              <div className={`col-12 importAsDropdown addToExistingAudience`}>
                                <a
                                  href="#"
                                  className="btnAddNewGreenDashed"
                                  data-bs-toggle="tooltip"
                                  data-bs-title="Add Audience / Group"
                                >
                                  <i className="bi bi-plus" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="mb-3">
                        <button
                          type="button"
                          className="btn btn-primary btn-lg btnSearch"
                          onClick={handleSearch}
                        >
                          <i className="bi bi-search" /> Search <i className="bi bi-people-fill" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <Review step={step} setStep={setStep} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FindContacts
