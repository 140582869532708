import * as React from "react"

import PrivateRoute from "../../components/privateRoute"
import FindContacts from "../../components/ContactsFinder/findContacts"
import ReduxWrapper from "../../redux/reduxWrapper"

const ContactsFinder = props => {
  return <PrivateRoute component={FindContacts} location={props.location} />
}

const WrappedPage = props => <ReduxWrapper element={<ContactsFinder {...props} />} />
export default WrappedPage
