import * as React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

const Review = ({ step, setStep }) => {
  const handleSearchAgain = () => {
    setStep("search")
  }

  return (
    <div className={`row step2_container${step !== "review" ? " d-none" : ""}`}>
      <div className="col-lg-12">
        <div className="table-responsive">
          <table id="tblContacts" className="table dataTable" cellSpacing="0" width="100%">
            <thead>
              <tr>
                <th width="5%">&nbsp;</th>
                <th width="35%">Name</th>
                <th width="25%" className="d-none d-lg-table-cell">
                  Website
                </th>
                <th width="15%" className="d-none d-md-table-cell">
                  Phone
                </th>
                <th width="20%" className="d-none d-md-table-cell">
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center tableColChkDel">
                  <div className="tableColChkDelBox">
                    <input
                      type="checkbox"
                      name="fileid[]"
                      id="C_ID1"
                      className="css-checkbox"
                      defaultChecked
                    />
                    <label htmlFor="C_ID1" className="css-checkbox-label" />
                    <span className="tableColChkSpan btn-secondary">
                      <i className="bi bi-building-fill" />
                    </span>
                  </div>
                </td>
                <td className="nowrap">Two Men and a Truck Charleston</td>
                <td className="nowrap d-none d-lg-table-cell">
                  <a
                    href="https://twomen.com/charleston/"
                    className="dashedLinked"
                    target="_blank"
                    rel="noreferrer"
                  >
                    twomen.com/charleston/
                  </a>
                </td>
                <td className="d-none d-md-table-cell">843-225-9077</td>
                <td className="d-none d-md-table-cell">charleston@twomen.com</td>
              </tr>
              <tr>
                <td className="text-center tableColChkDel">
                  <div className="tableColChkDelBox">
                    <input
                      type="checkbox"
                      name="fileid[]"
                      id="C_ID2"
                      className="css-checkbox"
                      defaultChecked
                    />
                    <label htmlFor="C_ID2" className="css-checkbox-label" />
                    <span className="tableColChkSpan btn-secondary">
                      <i className="bi bi-building-fill" />
                    </span>
                  </div>
                </td>
                <td className="nowrap">Budget Charleston</td>
                <td className="nowrap d-none d-lg-table-cell">
                  <a
                    href="https://www.budgetcharleston.com/"
                    className="dashedLinked"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.budgetcharleston.com/
                  </a>
                </td>
                <td className="d-none d-md-table-cell">843-225-9077</td>
                <td className="d-none d-md-table-cell">info@budgetcharleston.com</td>
              </tr>
              <tr>
                <td className="text-center tableColChkDel">
                  <div className="tableColChkDelBox">
                    <input
                      type="checkbox"
                      name="fileid[]"
                      id="C_ID3"
                      className="css-checkbox"
                      defaultChecked
                    />
                    <label htmlFor="C_ID3" className="css-checkbox-label" />
                    <span className="tableColChkSpan btn-secondary">
                      <i className="bi bi-building-fill" />
                    </span>
                  </div>
                </td>
                <td className="nowrap">Gentle Giant Moving Company Charleston</td>
                <td className="nowrap d-none d-lg-table-cell">
                  <a
                    href="https://www.gentlegiant.com/charleston-sc/"
                    className="dashedLinked"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.gentlegiant.com/charleston-sc/
                  </a>
                </td>
                <td className="d-none d-md-table-cell">843-203-7670</td>
                <td className="d-none d-md-table-cell">charleston@gentlegiant.com</td>
              </tr>
              <tr>
                <td className="text-center tableColChkDel">
                  <div className="tableColChkDelBox">
                    <input
                      type="checkbox"
                      name="fileid[]"
                      id="C_ID4"
                      className="css-checkbox"
                      defaultChecked
                    />
                    <label htmlFor="C_ID4" className="css-checkbox-label" />
                    <span className="tableColChkSpan btn-secondary">
                      <i className="bi bi-building-fill" />
                    </span>
                  </div>
                </td>
                <td className="nowrap">Move Pros</td>
                <td className="nowrap d-none d-lg-table-cell">
                  <a
                    href="https://www.moveprosllc.com/"
                    className="dashedLinked"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.moveprosllc.com/
                  </a>
                </td>
                <td className="d-none d-md-table-cell">843-732-7373</td>
                <td className="d-none d-md-table-cell">info@moveprosllc.com</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="col-lg-12 mt-5">
        <div className="MarketingAudienceRecommendedContainer">
          <input
            type="checkbox"
            name="MarketingAudienceRecommended"
            id="MarketingAudienceRecommended1"
            className="css-checkbox"
            value="ValidateEmails"
            defaultChecked
          />
          <label htmlFor="MarketingAudienceRecommended1" className="css-checkbox-label me-2">
            <i className="bi bi-check2" /> Recommended: Validate Emails &nbsp;
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="ms-1 d-inline-block">
                  Credits Required: Will clean the list, check for non-deliverable, honeypots, etc.
                </Tooltip>
              }
            >
              <i className="bi bi-question-circle-fill" />
            </OverlayTrigger>
          </label>
        </div>
        <div className="mt-1 mb-3 MarketingAudienceRecommendedContainer">
          <input
            type="checkbox"
            name="MarketingAudienceRecommended"
            id="MarketingAudienceRecommended2"
            className="css-checkbox"
            value="ScrubPhoneNumbers"
            defaultChecked
          />
          <label htmlFor="MarketingAudienceRecommended2" className="css-checkbox-label me-2">
            <i className="bi bi-shield-fill" /> Recommended: Scrub Phone Numbers &nbsp;
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="ms-1 d-inline-block">
                  Credits Required: Will scrub against Do Not Call list
                </Tooltip>
              }
            >
              <i className="bi bi-question-circle-fill" />
            </OverlayTrigger>
          </label>
        </div>
        <div className="mb-3 row">
          <label className="col-lg-3 col-sm-4 col-form-label">Tag all contacts with...</label>
          <div className="col-lg-9 col-sm-8">
            <div className="bootstrap-tagsinput">
              <input type="text" placeholder="Add tags" />
              <button className="btn btn-sm btn-primary btnSaveTag">
                <i className="fa fa-save" />
              </button>
            </div>
            <input
              type="text"
              name="feed_tags"
              id="feed_tags"
              placeholder="Add tags"
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <label className="col-lg-3 col-sm-4 col-form-label">Optional: Set all Records to...</label>
          <div className="col-lg-9 col-sm-8">
            <div className="row row-cols-lg-auto g-2 align-items-center mb-1">
              <div className="col-12">
                <select className="form-select setAllRecordsTo">
                  <option value="Ignore / No Auto-Apply">Ignore / No Auto-Apply</option>
                  <option value="Industry">Industry</option>
                </select>
              </div>
              <div className="col-12 industryOpt d-none">
                <select className="form-select">
                  <option>806_industrieshere</option>
                </select>
              </div>
              <div className="col-12">
                <a href="#" className="btnAddNewGreenDashed">
                  <i className="bi bi-plus-lg" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 row">
          <label className="col-lg-3 col-sm-4 col-form-label">Notes about this import</label>
          <div className="col-lg-9 col-sm-8">
            <textarea name="comments" className="form-control" />
          </div>
        </div>
        <div className="MarketingAudienceRecommendedContainer">
          <button className="btn btn-default btn-lg btnResetSearch my-1" onClick={handleSearchAgain}>
            <i className="bi bi-arrow-left-circle" /> Reset & Search Again
          </button>
          &nbsp;
          <button className="btn btn-primary btn-lg my-1">
            <i className="bi bi-check2" /> Save Contacts <i className="bi bi-people-fill" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Review
