import * as React from "react"
import { useState } from "react"

const FindBusinessOptions = ({ searchType }) => {
  const [linkedInFindBy, setLinkedInFindBy] = useState("Job Title")

  const handleLinkedInFindBy = event => {
    setLinkedInFindBy(event.target.value)
  }

  return (
    <div className="row mb-3 business_opt">
      {searchType === "AIChatGPT" && (
        <div className="col-lg-12 searchTypeContainer AIChatGPT_box">
          <div className="row row-cols-lg-auto g-2 align-items-center">
            <div className="col-12">
              <strong>Find</strong>
            </div>
            <div className="col-12">
              <select className="form-select page_limit">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="100">100</option>
              </select>
            </div>
            <div className="col-12">
              <input
                type="text"
                className="form-control"
                name="search"
                placeholder="i.e. moving companies in Charleston, SC"
              />
            </div>
          </div>
        </div>
      )}
      {searchType === "GoogleBusiness" && (
        <div className="col-lg-12 searchTypeContainer GoogleBusiness_box">
          <div className="row row-cols-lg-auto g-2 align-items-center">
            <div className="col-12">
              <input type="text" className="form-control" placeholder="Type i.e. movers" />
            </div>
            <div className="col-12">
              <input type="text" className="form-control" placeholder="Location" />
            </div>
            <div className="col-12">
              <select className="form-select page_limit">
                <option value="5">5km</option>
                <option value="10">10km</option>
                <option value="20">20km</option>
                <option value="50">50km</option>
                <option value="100">100km</option>
              </select>
            </div>
          </div>
        </div>
      )}
      {searchType === "FacebookBusinesses" && (
        <div className="col-lg-12 searchTypeContainer FacebookBusinesses_box">Coming soon</div>
      )}
      {searchType === "LinkedIn" && (
        <div className="col-lg-12 searchTypeContainer LinkedIn_box">
          <div className="row row-cols-lg-auto g-2 align-items-center">
            <div className="col-12">Find by </div>
            <div className="col-12">
              <select className="form-select LinkedInFindBy" onChange={handleLinkedInFindBy}>
                <option value="Job Title">Job Title</option>
                <option value="Industry">Industry</option>
              </select>
            </div>
            {linkedInFindBy === "Job Title" && (
              <div className="col-12 LinkedInFindByJobTitleInput">
                <input type="text" className="form-control" placeholder="Title i.e. Marketing Manager" />
              </div>
            )}
            <div className="col-12">
              <input type="text" className="form-control" placeholder="Location" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FindBusinessOptions
