import * as React from "react"
import { Editor } from "@tinymce/tinymce-react"

if (typeof window !== "undefined") {
  require("tinymce/tinymce")
  require("tinymce/icons/default")
  require("tinymce/themes/silver")
  require("tinymce/plugins/link")
  require("tinymce/plugins/image")
  require("tinymce/plugins/advlist")
  require("tinymce/plugins/autolink")
  require("tinymce/plugins/lists")
  require("tinymce/plugins/preview")
  require("tinymce/plugins/searchreplace")
  require("tinymce/plugins/table")
  require("tinymce/plugins/wordcount")
  require("tinymce/models/dom/model")
}

const FindPeopleOptions = ({ findPeopleBy }) => {
  return (
    <>
      {findPeopleBy === "RadiusSearch" && (
        <div className="row mb-3 people_opt find_people_by_radius">
          <div className="col-lg-12">
            <div className="row row-cols-lg-auto g-2 align-items-center">
              <div className="col-12">
                <input type="text" className="form-control" placeholder="Location" />
              </div>
              <div className="col-12">
                <select className="form-select page_limit">
                  <option value="5">5km</option>
                  <option value="10">10km</option>
                  <option value="20">20km</option>
                  <option value="50">50km</option>
                  <option value="100">100km</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}
      {findPeopleBy === "CopyFromFB" && (
        <div className="row mb-3 find_people_by_FB">
          <div className="col-lg-12">
            <Editor
              value=""
              init={{
                branding: false,
                height: 350,
                menubar: false,
                plugins: [],
                toolbar: "",
              }}
            />
          </div>
        </div>
      )}
      {findPeopleBy === "CopyFromLinkedIn" && (
        <div className="row mb-3 find_people_by_LinkedIn">
          <div className="col-lg-12">
            <Editor
              value=""
              init={{
                branding: false,
                height: 350,
                menubar: false,
                plugins: [],
                toolbar: "",
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default FindPeopleOptions
